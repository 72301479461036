<template>
  <el-dialog v-model="dialogVisible" title="條件搜尋" width="800px">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="search-form"
    >
      <el-form-item class="form-item">
        <template #label>
          <span class="label">套餐名稱</span>
        </template>
        <el-input v-model="ruleForm.name" placeholder="請輸入套餐名稱" maxlength="15" show-word-limit />
      </el-form-item>
      <el-form-item class="form-item" prop="date">
        <template #label>
          <span class="label">活動日期</span>
        </template>
        <el-date-picker
          v-model="ruleForm.date"
          type="daterange"
          unlink-panels
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          :shortcuts="shortcuts"
          format="YYYY/MM/DD"
          value-format="YYYY/MM/DD"
        />
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">狀態</span>
        </template>
        <el-select v-model="ruleForm.available_status" clearable placeholder="請選擇狀態" style="width: 100%">
          <el-option label="未開始" :value="1" :key="1" />
          <el-option label="進行中" :value="2" :key="2" />
          <el-option label="已結束" :value="3" :key="3" />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">套餐內容名稱</span>
        </template>
        <el-cascader
          v-model="ruleForm.product_id"
          :options="getProductTree"
          :props="productProps"
          :show-all-levels="false"
          placeholder="請選擇或輸入商品名稱、ID"
          style="width: 100%"
          clearable
          filterable
        ></el-cascader>
      </el-form-item>

      <el-form-item class="form-item" style="grid-area: 3/1/3/3">
        <template #label>
          <span class="label">門市</span>
        </template>
        <el-cascader
          v-model="ruleForm.shop_id"
          :options="getStoreList"
          :show-all-levels="false"
          placeholder="請選擇或輸入門市名稱、代碼"
          style="width: 100%"
          clearable
          filterable
          :props="{ emitPath: false }"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          @click="handleSearch(ruleFormRef)"
          :disabled="!checkSubmit()"
          style="margin-left: 25px"
        >
          搜尋
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref } from 'vue';
import { useGetters, useActions } from '@/utils/mapStore';
import { useRouter } from 'vue-router';
export default {
  name: 'search-meal-dialog',
  setup() {
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    const productProps = { emitPath: false, label: 'name', value: 'id', children: 'product_list' };
    //設定初始值
    const getSearchCondition = useGetters('meal', ['getSearchCondition']);

    if (dialogVisible) {
      if (getSearchCondition.value) ruleForm.value = JSON.parse(JSON.stringify(getSearchCondition.value));
      else
        ruleForm.value = {
          available_status: '',
          date: [],
          name: '',
          product_id: '',
          shop_id: '',
        };
    }

    //各式選項-----------------------------------------------------
    const { getStoreList, getProductTree } = useGetters('common', ['getStoreList', 'getProductTree']);

    //日期相關-----------------------------------------------------
    //日期快捷
    const shortcuts = [
      {
        text: '當天',
        value: () => {
          const end = new Date();
          const start = new Date();
          return [start, end];
        },
      },
      {
        text: '當周',
        value: () => {
          const end = new Date();
          const start = new Date(end.getTime() - 3600 * 1000 * 24 * (end.getDay() === 0 ? 6 : end.getDay() - 1));
          return [start, end];
        },
      },
      {
        text: '當月',
        value: () => {
          const end = new Date();
          const start = new Date(`${end.toLocaleDateString().slice(0, 7)}/01`);
          return [start, end];
        },
      },
    ];

    //驗證與檢查-----------------------------------------------------------------
    const checkSubmit = () => {
      let isValid;
      //未選擇任一條件
      isValid = !Object.keys(ruleForm.value).every((e) => !ruleForm.value[e] || ruleForm.value[e].length === 0);
      //未改變任一條件
      if (getSearchCondition.value && isValid) {
        isValid = !(JSON.stringify(ruleForm.value) === JSON.stringify(getSearchCondition.value));
      }
      return isValid;
    };

    //送出
    const { doGetMealList, doGetMealSummary } = useActions('meal', ['doGetMealList', 'doGetMealSummary']);
    const router = useRouter();
    const handleSearch = async (form) => {
      try {
        if (!form) return;
        await form.validate();
        // console.log(ruleForm.value);
        await doGetMealSummary(ruleForm.value);
        await doGetMealList({ start: 0, search: ruleForm.value });
        router.push('/product/meal?page=1');
        close();
      } catch (e) {
        e;
      }
    };
    return {
      dialogVisible,
      close,
      cusProps,
      shortcuts,
      ruleFormRef,
      ruleForm,
      productProps,
      getStoreList,
      getProductTree,
      checkSubmit,
      handleSearch,
    };
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  width: 100%;
  display: grid;
  grid-template-rows: 100px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
</style>
